import React from 'react'
import { graphql } from 'gatsby'
import { Container, Card, Row, Col, Media } from 'reactstrap'
import ScrollAnimation from 'react-animate-on-scroll'

import Banner from '../components/Banner'
import bannerImage from '../img/contact.jpg'
import Advisors from '../components/Advisors'
import Layout from '../components/Layout'

const AboutPage = ({ data }) => {
  const { edges: members } = data.members
  const { edges: advisors } = data.advisor
  const { edges: partners } = data.partner

  console.log('Partners: ', partners)
  return (
    <Layout>
      <Banner image={bannerImage} title="About Us" />

      <section className="padded-section bg-primary">
        <Container>
          <h1>Who We Are</h1>
          <p>
            SafeStart Medical is the result of a career-long passion for patient
            safety. Despite health care information technology and the WHO
            Universal Protocol checklists, Never Events continue to occur at an
            alarming rate. We need to address medical system errors that cause
            great patient harm.
          </p>
          <blockquote>
            SafeStart is not just a checklist - it’s a safety system.
          </blockquote>
          <p>
            We drive the safety process from the front office through the
            operating room, engaging patients and staff along the way. Our team
            of surgeons, patients, and healthcare experts put patient safety
            first. <strong>Always</strong>.
          </p>
        </Container>
      </section>

      <section className="padded-section bg-light team-section">
        <Container>
          <h1>Team</h1>
          {members.map(({ node: member }) => (
            <ScrollAnimation
              animateIn="fadeIn"
              animateOnce={true}
              key={member.id}
            >
              <Card body>
                <Media>
                  <Media left>
                    <figure className="image">
                      <img
                        src={member.frontmatter.thumbnail.publicURL}
                        alt="Team Member"
                      />
                    </figure>
                  </Media>
                  <Media body>
                    <div className="content">
                      <h3 className="title is-4">
                        <strong>{member.frontmatter.title}</strong>
                      </h3>
                      <p className="team-designation">
                        {member.frontmatter.designations}
                      </p>
                      {member.frontmatter.bio}
                    </div>
                  </Media>
                </Media>
              </Card>
            </ScrollAnimation>
          ))}
        </Container>
      </section>

      <section className="bg-light team-section">
        <Container>
          <h1>Advisory Board</h1>
          <Advisors advisors={advisors} />
        </Container>
      </section>

      <section className="padded-section">
        <Container>
          <h1>Our Partners</h1>
          <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
            <Row>
              {partners.map(({ node: partner }) => (
                <Col
                  xs="6"
                  sm="4"
                  md="3"
                  className="text-center"
                  key={partner.id}
                >
                  <a
                    href={partner.frontmatter.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="img-fluid"
                      src={partner.frontmatter.logo.publicURL}
                      alt={partner.frontmatter.name}
                    />
                  </a>
                </Col>
              ))}
            </Row>
          </ScrollAnimation>
        </Container>
      </section>
    </Layout>
  )
}

export default AboutPage

export const aboutQuery = graphql`
  query aboutQuery {
    members: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order] }
      filter: { frontmatter: { templateKey: { eq: "member-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            designations
            thumbnail {
              publicURL
            }
            bio
          }
        }
      }
    }
    advisor: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] }
      filter: { frontmatter: { templateKey: { eq: "advisor-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            designations
            thumbnail {
              publicURL
            }
            bio
          }
        }
      }
    }
    partner: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order] }
      filter: { frontmatter: { templateKey: { eq: "partner-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            link
            logo {
              publicURL
            }
          }
        }
      }
    }
  }
`
