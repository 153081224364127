import React from 'react'
import { Card, CardBody, Row, Col, Media } from 'reactstrap'
import ScrollAnimation from 'react-animate-on-scroll'

export default ({ advisors }) => {
  const advisorCards = advisors.map((advisor, index) => {
    return (
      <ScrollAnimation animateIn="fadeIn" animateOnce={true} key={index}>
        <Card body>
          <Media>
            <Media left>
              <figure className="image">
                <img
                  src={advisor.node.frontmatter.thumbnail.publicURL}
                  alt="Advisor photo"
                />
              </figure>
            </Media>
            <Media body>
              <div className="content">
                <h3 className="title is-4">
                  <strong>{advisor.node.frontmatter.title}</strong>
                </h3>
                <p className="team-designation">
                  {advisor.node.frontmatter.designations}
                </p>
                {advisor.node.frontmatter.bio}
              </div>
            </Media>
          </Media>
        </Card>
      </ScrollAnimation>
    )
  })

  return <div>{advisorCards}</div>
}
